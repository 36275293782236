@tailwind base;
@tailwind components;
@tailwind utilities;

input:focus {
    outline: none !important;
    outline-style: none;
    /* border: 2px solid #2E0040 !important; */
    box-shadow: none;
  }

textarea:focus {
    outline: none !important;
    outline-style: none;
    /* border: 2px solid #2E0040 !important; */
    box-shadow: none;
  }

  button, input, optgroup, select, textarea {
    
    font-size: inherit;
    
}